import { Button } from "../../../components/button";

export const OfferCard = ({
    period,
    price,
    link,
}: {
    period: string;
    price: string;
    link: string;
}) => {
    return (
        <div className="p-6 xs:p-10 w-full xs:w-[260px] xl:w-[260px] 2xl:w-[320px] h-[210px] xs:h-[320px] xl:h-[357px] shadow-3xl rounded-3xl flex flex-col justify-between">
            <div className="text-base xs:text-2xl font-bold">{period}</div>
            <div className="text-3xl xs:text-5xl font-semibold flex-grow flex items-center">
                {price}
            </div>
            <Button>Купить</Button>
        </div>
    );
};
