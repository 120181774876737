import { OfferCard } from "../offer-card";

export const OffersList = () => {
    const offersData: { period: string; price: string; link: string }[] = [
        {
            period: "3 МЕСЯЦА",
            price: "$500",
            link: "#",
        },
        {
            period: "6 МЕСЯЦЕВ",
            price: "$600",
            link: "#",
        },
        {
            period: "1 ГОД",
            price: "$800",
            link: "#",
        },
    ];
    return (
        <div className="flex flex-wrap xl:flex-nowrap gap-7 xs:gap-10 justify-center w-full">
            {offersData.map((offer) => (
                <OfferCard
                    period={offer.period}
                    price={offer.price}
                    link={offer.link}
                />
            ))}
        </div>
    );
};
