import Joi from "joi";

import { AuthFormValues } from "./auth.type";
import { InputType } from "../../types/input.enum";

export const validationAuth = Joi.object<AuthFormValues>({
    [InputType.Email]: Joi.string()
        .pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i)
        .required()
        .messages({
            "string.empty": "errors:required",
            "string.pattern.base": "errors:invalid_email_format",
        }),
});
