import { PropsWithChildren } from "react";

import { Footer } from "./footer";
import { Header } from "./header";
import { Paper } from "./paper";

export const Layout = ({ children }: PropsWithChildren) => {
    return (
        <div className="wrapper">
            <Header />
            <Paper>
                <main className="flex-grow flex justify-center items-center">
                    {children}
                </main>
            </Paper>
            <Footer />
        </div>
    );
};
