import { IconQr } from "./icon/icon-qr";
import { Button } from "../../components/button";

export const AppDownload = () => {
    return (
        <div className="max-w-[680px] flex flex-col items-center">
            <div className="title">Адвент-календарь</div>
            <div className="subTitle">
                Зарегистрируйтесь для покупки уникального контента и сохранения
                ваших платежных данных в безопасности
            </div>
            <IconQr /> <Button>Скачать и начать</Button>
        </div>
    );
};
