export const IconFacebook = () => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.9974 17.9987C35.9974 8.05764 27.9398 0 17.9987 0C8.05762 0 0 8.05764 0 17.9987C0 26.9826 6.58184 34.4288 15.1864 35.7782V23.2002H10.6171V17.9962H15.1864V14.0306C15.1864 9.52055 17.8723 7.02818 21.9849 7.02818C23.9535 7.02818 26.0151 7.37907 26.0151 7.37907V11.8091H23.7446C21.5076 11.8091 20.811 13.1972 20.811 14.6188V17.9962H25.8035L25.0062 23.2002H20.8136V35.7782C29.4182 34.4288 36 26.9826 36 17.9987H35.9974Z"
                fill="black"
            />
        </svg>
    );
};
