import { useSendCodeAgain } from "./use-send-code-again";

export const SendCodeAgain = () => {
    const { errorBack, handleSubmit, disabledBtn, formattedTime } =
        useSendCodeAgain();
    return (
        <>
            <button
                disabled={disabledBtn}
                onClick={handleSubmit}
                className="block m-auto mt-3 disabled:text-grey text-sm"
            >
                Отправить код повторно {disabledBtn && "через 00:"}
                {disabledBtn && formattedTime}
            </button>
            <div className="textErrorSmall">{errorBack}</div>
        </>
    );
};
