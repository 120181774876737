import { Button } from "../../../components/button";
import { IconApple } from "../../../components/button/icons/icon-apple";
import { IconGoogle } from "../../../components/button/icons/icon-google";

export const SocialLogin = () => {
    return (
        <div className="mb-7 xs:mb-14 flex gap-4 xs:gap-8 flex-wrap xs:flex-nowrap">
            <Button variant="translate" startIcon={<IconGoogle />}>
                Sign in with Google
            </Button>
            <Button startIcon={<IconApple />}>Sign in with Apple</Button>
        </div>
    );
};
