import { InputCodeVerificationProps } from "./input-code-verification.type";
import { useInputCodeVerification } from "./use-input-code-verification";

import "./input-сode-verification.scss";

export const InputCodeVerification = ({
    emailNotExist,
    error,
    setCode,
}: InputCodeVerificationProps) => {
    const { inputsRef, handleChange, handleKeyDown, handlePaste } =
        useInputCodeVerification({ setCode });

    return (
        <div className="flex flex-col items-center">
            <div className="list-code-input">
                {Array.from({ length: 6 }).map((_, index) => (
                    <input
                        disabled={!emailNotExist}
                        key={index}
                        type="tel"
                        maxLength={1}
                        inputMode="numeric"
                        className={`code-input ${error && "error"}`}
                        ref={(el) => (inputsRef.current[index] = el)}
                        onChange={(event) => handleChange(index, event)}
                        onKeyDown={(event) => handleKeyDown(index, event)}
                        onPaste={(event) => handlePaste(event)}
                        autoFocus={index === 0}
                    />
                ))}
            </div>
            <div className="textErrorSmall">{error}</div>
        </div>
    );
};
