import { Logo } from "./icons/logo";
import { Button } from "../../components/button";

export const Header = () => {
    return (
        <div className="py-5 xs:py-11 flex items-center justify-between">
            <Logo />
            <Button size="small">Помощь</Button>
        </div>
    );
};
