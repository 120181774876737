import { createBrowserRouter } from "react-router-dom";
import { VerificationGuard } from "./guards";
import { AuthGuard } from "./guards/auth-guard";
import { AppDownload } from "./pages/app-download";
import { Auth } from "./pages/auth";
import { Offers } from "./pages/offers";
import { UserInfo } from "./pages/user-info";
import { Verification } from "./pages/verification";
import { Routes } from "./types";

export const router = createBrowserRouter([
    { path: Routes.Auth, element: <Auth /> },
    {
        path: Routes.Verification,
        element: (
            <VerificationGuard>
                <Verification />
            </VerificationGuard>
        ),
    },
    {
        path: Routes.UserInfo,
        element: (
            <AuthGuard>
                <UserInfo />
            </AuthGuard>
        ),
    },
    {
        path: Routes.Offers,
        element: (
            <AuthGuard>
                <Offers />
            </AuthGuard>
        ),
    },
    {
        path: Routes.AppDownload,
        element: (
            <AuthGuard>
                <AppDownload />
            </AuthGuard>
        ),
    },
]);
