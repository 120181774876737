import { PropsWithChildren } from "react";

import { Navigate } from "react-router-dom";

import { Routes } from "../types";
import { LocalStorageKeys } from "../types/local-storage-keys.enum";

export const AuthGuard = ({ children }: PropsWithChildren) => {
    const isAuthenticated = !!localStorage.getItem(
        LocalStorageKeys.AccessToken,
    );

    if (!isAuthenticated) return <Navigate to={Routes.Auth} />;

    return <>{children}</>;
};
