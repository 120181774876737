import { PropsWithChildren } from "react";

import { ButtonProps } from "./button.type";
import { IconSpin } from "../../icons/icon-spin";

export const Button = ({
    children,
    className,
    variant = "black",
    size = "large",
    loading = false,
    disabled = false,
    startIcon,
    click,
}: PropsWithChildren<ButtonProps>) => {
    const cssVariant =
        variant === "black"
            ? "text-primary bg-secondary border border-solid border-secondary "
            : "text-secondary border border-solid border-greyLine ";

    const cssSize = size === "large" ? "w-full" : " ";

    return (
        <button
            disabled={disabled}
            className={`${className && ""} ${cssVariant} ${cssSize} disabled:bg-greyLine disabled:border-greyLine   py-3 px-6 xs:py-4 xs:px-8 rounded-3xl font-bold text-base xs:text-xl`}
            onClick={click}
        >
            <div className="flex  justify-center items-center gap-2">
                {startIcon}
                {loading && <IconSpin />}
                {children}
            </div>
        </button>
    );
};
