import { Link } from "react-router-dom";

import { InputCodeVerification } from "./input-сode-verification";
import { SendCodeAgain } from "./send-code-again";
import { useVerification } from "./use-verification";
import { Button } from "../../components/button";
import { ButtonHelp } from "../../components/button-help";
import { Routes } from "../../types";

export const Verification = () => {
    const { email, code, errorBack, setCode, sendCodeVerification } =
        useVerification();

    return (
        <div className="max-w-[680px]">
            <div className="title">Введите код</div>
            <div className="flex">
                <div className="subTitle">
                    {email ? (
                        <>
                            Мы отправили верификационный код на почту&ensp;
                            {email}.&ensp;
                            <Link to={Routes.Auth}>
                                <span className="text-secondary underline">
                                    Изменить
                                </span>
                            </Link>
                        </>
                    ) : (
                        <>
                            Вы удалили почту введите ее заного&ensp;
                            <Link to={Routes.Auth}>
                                <span className="text-secondary underline">
                                    Вернуться назад
                                </span>
                            </Link>
                        </>
                    )}
                </div>
            </div>
            <InputCodeVerification
                emailNotExist={!!email}
                error={errorBack}
                setCode={setCode}
            />
            <SendCodeAgain />
            <div className="mt-6 xs:mt-10 mb-7 xs:mb-14">
                <Button
                    click={sendCodeVerification}
                    disabled={code.length !== 6 || !!errorBack.length}
                >
                    Далее
                </Button>
            </div>
            <ButtonHelp />
        </div>
    );
};
