import { configureStore } from "@reduxjs/toolkit";

import { splitApi } from "../api/api";

export const store = configureStore({
    reducer: {
        [splitApi.reducerPath]: splitApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(splitApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
