import { OffersList } from "./offers-list";

export const Offers = () => {
    return (
        <div className="max-w-[1080px] w-full">
            <div className="title">Покупайте уже</div>
            <div className="subTitle">
                Поддержка подписчиков 24/7 <br></br>
                Гарантия сброса массы
            </div>
            <OffersList />
        </div>
    );
};
