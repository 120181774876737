import { PropsWithChildren } from "react";

import { useFormContext } from "react-hook-form";

import { Button } from "../button/button";

export const ButtonForm = ({
    children,
    loading,
}: PropsWithChildren<{ loading: boolean }>) => {
    const {
        formState: { isValid },
    } = useFormContext();

    return (
        <div className="mt-6 xs:mt-10 mb-7 xs:mb-14">
            <Button loading={loading} disabled={!isValid || loading}>
                {children}
            </Button>
        </div>
    );
};
