import { SocialLogin } from "./social-login";
import { useAuth } from "./use-auth";
import { validationAuth } from "./validator-auth";
import { ButtonForm } from "../../components/button-form";
import { ButtonHelp } from "../../components/button-help";
import { Form } from "../../components/form";
import { Input } from "../../components/input";
import { InputType } from "../../types/input.enum";

import "./auth.scss";

export const Auth = () => {
    const { defaultValues, errorBack, onSubmit, isLoading } = useAuth();
    return (
        <div className="max-w-[680px]">
            <div className="title">Регистрация/Вход</div>
            <div className="subTitle">
                Зарегистрируйтесь для покупки уникального контента и сохранения
                ваших платежных данных в безопасности
            </div>
            <Form
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                validation={validationAuth}
                errorBack={errorBack}
            >
                <Input name={InputType.Email} label={"Email*"} />
                <ButtonForm loading={isLoading}>Далее</ButtonForm>
            </Form>
            <div className="text-with-lines regular-black-18 mb-7 xs:mb-14">
                или
            </div>
            <SocialLogin />
            <ButtonHelp />
            <div className="regular-light-16 mb-7 xs:mb-14 ">
                Регистрируясь я соглашаюсь ...
            </div>
        </div>
    );
};
