import { PropsWithChildren } from "react";

import { Navigate } from "react-router-dom";

import { Routes } from "../types";
import { LocalStorageKeys } from "../types/local-storage-keys.enum";

export const VerificationGuard = ({ children }: PropsWithChildren) => {
    const hasEmail = !!localStorage.getItem(LocalStorageKeys.Email);
    const hasToken = !!localStorage.getItem(LocalStorageKeys.AccessToken);

    if (hasEmail && hasToken) return <Navigate to={Routes.UserInfo} />;

    if (!hasEmail && !hasToken) return <Navigate to={Routes.Auth} />;

    return <>{children}</>;
};
