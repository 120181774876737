import { useUserInfo } from "./use-user-info";
import { validationUserInfo } from "./validator-user-info";
import { ButtonForm } from "../../components/button-form";
import { ButtonHelp } from "../../components/button-help";
import { Form } from "../../components/form";
import { Input } from "../../components/input";
import { InputType } from "../../types/input.enum";

export const UserInfo = () => {
    const { defaultValues, errorBack, onSubmit, isLoading } = useUserInfo();

    return (
        <div>
            <div className="title">Ваши данные</div>
            <div className="subTitle">
                Как мы и пользователи dSync можем к вам обращаться
            </div>
            <Form
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                errorBack={errorBack}
                validation={validationUserInfo}
            >
                <Input name={InputType.FirstName} label={"Имя*"} />
                <Input name={InputType.LastName} label={"Фамилия"} />
                <ButtonForm loading={isLoading}>Далее</ButtonForm>
            </Form>
            <ButtonHelp />
        </div>
    );
};
