import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { AuthFormValues } from "./auth.type";
import { useGetCodeVerificationMutation } from "../../api/api";
import { Routes } from "../../types";
import { LocalStorageKeys } from "../../types/local-storage-keys.enum";

export const useAuth = () => {
    const [getCodeVerification, { isLoading }] =
        useGetCodeVerificationMutation();

    const defaultValues: AuthFormValues = {
        email: "",
    };

    const [errorBack, setError] = useState("");
    const navigate = useNavigate();

    const onSubmit = async (data: AuthFormValues) => {
        setError("");

        localStorage.clear();

        try {
            await getCodeVerification(data).unwrap();

            localStorage.setItem(LocalStorageKeys.Email, data.email);
            navigate(Routes.Verification);
        } catch (e) {
            setError("Ошибка");
        }
    };
    return { defaultValues, errorBack, onSubmit, isLoading };
};
