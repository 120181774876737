import { ChangeEvent, ClipboardEvent, KeyboardEvent, useRef } from "react";

export const useInputCodeVerification = ({
    setCode,
}: {
    setCode: (code: string) => void;
}) => {
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

    const focusEmptyInput = (inputIndex: number) => {
        for (let i = inputIndex + 1; i < inputsRef.current.length; i++) {
            if (inputsRef.current[i]?.value === "") {
                inputsRef.current[i]?.focus();
                break;
            }
        }
    };

    const handleChange = (
        index: number,
        event: ChangeEvent<HTMLInputElement>,
    ) => {
        const currentInput = event.target.value;

        if (currentInput) {
            focusEmptyInput(index);
        }

        const code = inputsRef.current.map((item) => item?.value).join("");
        setCode(code);
    };

    const handleKeyDown = (
        index: number,
        event: KeyboardEvent<HTMLInputElement>,
    ) => {
        const prevInput = inputsRef.current[index - 1];
        if (event.key === "ArrowRight") {
            inputsRef.current[index + 1]?.focus();
        }
        if (event.key === "ArrowLeft") {
            if (prevInput) {
                prevInput.focus();

                setTimeout(() => {
                    prevInput.setSelectionRange(1, 1);
                }, 0);
            }
        }

        if (
            event.key === "Backspace" &&
            !inputsRef.current[index]?.value &&
            prevInput
        ) {
            prevInput.focus();
            prevInput.value = "";
        }
    };

    const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
        const pastedData = event.clipboardData.getData("text").split("");

        pastedData.forEach((item, i) => {
            if (i < inputsRef.current.length) {
                const inputRefData = inputsRef.current[i];
                if (inputRefData) {
                    inputRefData.value = item;
                }
            }
        });
        inputsRef.current[inputsRef.current.length - 1]?.focus();
        const code = inputsRef.current.map((item) => item?.value).join("");
        setCode(code);
    };

    return { inputsRef, handleChange, handleKeyDown, handlePaste };
};
