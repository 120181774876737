import { IconFacebook } from "./icons/icon-facebook";
import { IconInsta } from "./icons/icon-insta";
import { IconTwitter } from "./icons/icon-twitter";

export const Footer = () => {
    const social = [
        { Component: IconFacebook, link: "#" },
        { Component: IconInsta, link: "#" },
        { Component: IconTwitter, link: "#" },
    ];
    return (
        <div className="py-10 xs:py-14  flex items-center justify-between flex-wrap xs:flex-nowrap">
            <div className="mb-6 xs:mb-0">
                <div className=" medium-black-24 mb-3 xs:mb-5">
                    Follow on social service
                </div>
                <div className="flex gap-4 flex-wrap">
                    {social.map(({ Component, link }, index) => (
                        <Component key={index} />
                    ))}
                </div>
            </div>
            <div className="regular-light-18">Copyright 2020@ all right</div>
        </div>
    );
};
