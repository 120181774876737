import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { userLoadingData } from "./api.type";
import { AuthFormValues } from "../pages/auth/auth.type";
import { UserInfoFormValues } from "../pages/user-info/user-info.type";
import { LocalStorageKeys } from "../types/local-storage-keys.enum";

export const splitApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "https://habitly.ddns.net/api",
        prepareHeaders: (headers) => {
            headers.set("Content-Type", "application/json");

            const token = localStorage.getItem(LocalStorageKeys.AccessToken);
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCodeVerification: builder.mutation({
            query: (email: AuthFormValues) => {
                return {
                    url: "/login/code",
                    method: "POST",
                    body: JSON.stringify(email),
                };
            },
        }),
        userLogin: builder.mutation({
            query: (userData: userLoadingData) => {
                return {
                    url: "/login",
                    method: "POST",
                    body: JSON.stringify(userData),
                };
            },
        }),
        userInfo: builder.mutation({
            query: (userData: UserInfoFormValues) => {
                return {
                    url: "/profile",
                    method: "POST",
                    body: JSON.stringify(userData),
                };
            },
        }),
    }),
});

export const {
    useGetCodeVerificationMutation,
    useUserLoginMutation,
    useUserInfoMutation,
} = splitApi;
