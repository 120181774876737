import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { UserInfoFormValues } from "./user-info.type";
import { useUserInfoMutation } from "../../api/api";
import { Routes } from "../../types";

export const useUserInfo = () => {
    const [useUserInfo, { isLoading }] = useUserInfoMutation();

    const defaultValues: UserInfoFormValues = {
        firstName: "",
        lastName: "",
    };
    const [errorBack, setError] = useState("");
    const navigate = useNavigate();

    const onSubmit = async (data: UserInfoFormValues) => {
        setError("");
        try {
            await useUserInfo(data).unwrap();

            navigate(Routes.Offers);
        } catch (e) {
            setError("Ошибка");
        }
    };

    return { defaultValues, errorBack, onSubmit, isLoading };
};
