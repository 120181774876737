export const IconApple = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.3428 17.1447C21.0101 17.9133 20.6163 18.6208 20.16 19.2713C19.5381 20.158 19.0288 20.7719 18.6364 21.1127C18.028 21.6722 17.3762 21.9587 16.6782 21.975C16.1771 21.975 15.5728 21.8324 14.8693 21.5432C14.1636 21.2553 13.515 21.1127 12.922 21.1127C12.3 21.1127 11.633 21.2553 10.9195 21.5432C10.2049 21.8324 9.62928 21.9832 9.18916 21.9981C8.51981 22.0266 7.85264 21.7319 7.18668 21.1127C6.76164 20.742 6.22999 20.1064 5.59309 19.2061C4.90976 18.2446 4.34796 17.1297 3.90784 15.8587C3.43648 14.4857 3.2002 13.1563 3.2002 11.8692C3.2002 10.3948 3.51878 9.12321 4.15689 8.0576C4.6584 7.20166 5.32557 6.52647 6.16059 6.03081C6.99562 5.53514 7.89786 5.28256 8.86949 5.2664C9.40114 5.2664 10.0983 5.43085 10.9647 5.75405C11.8287 6.07834 12.3834 6.24279 12.6266 6.24279C12.8085 6.24279 13.4247 6.0505 14.4694 5.66714C15.4573 5.31162 16.2911 5.16441 16.9742 5.2224C18.8251 5.37178 20.2157 6.10142 21.1405 7.41595C19.4851 8.41896 18.6662 9.82379 18.6825 11.626C18.6975 13.0297 19.2067 14.1979 20.2076 15.1254C20.6611 15.5558 21.1676 15.8885 21.7312 16.1248C21.609 16.4793 21.48 16.8188 21.3428 17.1447ZM17.0978 0.440125C17.0978 1.54038 16.6958 2.56768 15.8946 3.51854C14.9277 4.64892 13.7582 5.30211 12.49 5.19904C12.4738 5.06705 12.4645 4.92812 12.4645 4.78214C12.4645 3.7259 12.9243 2.59552 13.7408 1.67127C14.1485 1.20331 14.667 0.814209 15.2957 0.503814C15.9231 0.19805 16.5166 0.0289566 17.0747 0C17.091 0.147086 17.0978 0.294182 17.0978 0.440111V0.440125Z"
                fill="white"
            />
        </svg>
    );
};
