import { useEffect, useState } from "react";

import { useGetCodeVerificationMutation } from "../../../api/api";
import { LocalStorageKeys } from "../../../types/local-storage-keys.enum";

export const useSendCodeAgain = () => {
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [timeDisabledBtn, setTimeDisabledBtn] = useState(30);

    const [getCodeVerification] = useGetCodeVerificationMutation();
    const [errorBack, setError] = useState("");

    const getNewCode = async () => {
        setError("");

        const email = localStorage.getItem(LocalStorageKeys.Email);
        if (!email) return;

        try {
            await getCodeVerification({ email }).unwrap();
        } catch (e) {
            setError("Ошибка");
        }
    };

    const startTimer = () => {
        setDisabledBtn(true);
        setTimeDisabledBtn(30);

        const intervalId = setInterval(() => {
            setTimeDisabledBtn((prev) => {
                if (prev <= 1) {
                    clearInterval(intervalId);
                    setDisabledBtn(false);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    useEffect(() => {
        startTimer();
    }, []);

    const formattedTime =
        timeDisabledBtn < 10 ? `0${timeDisabledBtn}` : timeDisabledBtn;

    const handleSubmit = () => {
        getNewCode();
        startTimer();
    };
    return {
        errorBack,
        handleSubmit,
        disabledBtn,
        formattedTime,
    };
};
