import { PropsWithChildren } from "react";

import { joiResolver } from "@hookform/resolvers/joi";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { FormProps } from "./form.type";

export const Form = <FormValuesType extends FieldValues>({
    children,
    errorBack,
    defaultValues,
    validation,
    onSubmit,
}: PropsWithChildren<FormProps<FormValuesType>>) => {
    const form = useForm<FormValuesType>({
        mode: "onTouched",
        defaultValues,
        resolver: joiResolver(validation),
    });

    return (
        <FormProvider {...form}>
            <div className="textErrorSmall text-center mb-2">{errorBack}</div>
            <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
        </FormProvider>
    );
};
