import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useUserLoginMutation } from "../../api/api";
import { Routes } from "../../types";
import { LocalStorageKeys } from "../../types/local-storage-keys.enum";

export const useVerification = () => {
    const navigate = useNavigate();
    const [userLogin] = useUserLoginMutation();

    const email = localStorage.getItem(LocalStorageKeys.Email);

    const [code, setCode] = useState("");
    const [errorBack, setError] = useState("");

    useEffect(() => {
        if (code.length <= 6) {
            setError("");
        }
    }, [code]);

    const sendCodeVerification = async () => {
        setError("");

        const email = localStorage.getItem(LocalStorageKeys.Email);
        if (!email) return;

        try {
            const response: {
                accessToken: string;
                accessTokenExpiration: string;
                email: string;
            } = await userLogin({
                email,
                code,
                device: { token: "123", type: "Web" },
            }).unwrap();

            localStorage.setItem(
                LocalStorageKeys.AccessToken,
                response.accessToken,
            );
            navigate(Routes.UserInfo);
        } catch (e) {
            setError("Ошибка");
        }
    };
    return { email, code, errorBack, setCode, sendCodeVerification };
};
