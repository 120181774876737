import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { Layout } from "./layout";
import { store } from "./redux/store";
import { router } from "./router";
import "./styles/index.scss";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <Provider store={store}>
        <Layout>
            <RouterProvider router={router} />
        </Layout>
    </Provider>,
);
