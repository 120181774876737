export const IconGoogle = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_5365_1431)">
                <path
                    d="M24.2663 12.2763C24.2663 11.4605 24.2001 10.6404 24.059 9.83789H12.7402V14.4589H19.222C18.953 15.9492 18.0888 17.2676 16.8233 18.1054V21.1037H20.6903C22.9611 19.0137 24.2663 15.9272 24.2663 12.2763Z"
                    fill="#4285F4"
                />
                <path
                    d="M12.7401 24.0013C15.9766 24.0013 18.7059 22.9387 20.6945 21.1044L16.8276 18.106C15.7517 18.838 14.3627 19.2525 12.7445 19.2525C9.61388 19.2525 6.95946 17.1404 6.00705 14.3008H2.0166V17.3917C4.05371 21.4439 8.2029 24.0013 12.7401 24.0013Z"
                    fill="#34A853"
                />
                <path
                    d="M6.00277 14.3007C5.50011 12.8103 5.50011 11.1965 6.00277 9.70618V6.61523H2.01674C0.314734 10.006 0.314734 14.0009 2.01674 17.3916L6.00277 14.3007Z"
                    fill="#FBBC04"
                />
                <path
                    d="M12.7401 4.74966C14.4509 4.7232 16.1044 5.36697 17.3434 6.54867L20.7695 3.12262C18.6001 1.0855 15.7208 -0.034466 12.7401 0.000808666C8.2029 0.000808666 4.05371 2.55822 2.0166 6.61481L6.00264 9.70575C6.95064 6.86173 9.60947 4.74966 12.7401 4.74966Z"
                    fill="#EA4335"
                />
            </g>
            <defs>
                <clipPath id="clip0_5365_1431">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
