export const IconTwitter = () => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.0007 0C8.05921 0 0 8.06067 0 18.0007C0 27.9423 8.05921 36 18.0007 36C27.9423 36 36 27.9423 36 18.0007C36 8.06067 27.9415 0 18.0007 0ZM27.068 14.3713C27.0775 14.5658 27.0811 14.761 27.0811 14.9583C27.0811 20.9532 22.5183 27.8648 14.1747 27.8648C11.6133 27.8648 9.22807 27.114 7.22076 25.8275C7.57602 25.8692 7.9364 25.8904 8.30263 25.8904C10.4284 25.8904 12.3845 25.1652 13.9364 23.9488C11.9518 23.9115 10.277 22.5994 9.69956 20.7975C9.97588 20.8516 10.2602 20.8787 10.5526 20.8787C10.9664 20.8787 11.367 20.8246 11.7478 20.72C9.67178 20.3034 8.10819 18.4686 8.10819 16.2727C8.10819 16.2529 8.10819 16.2339 8.10892 16.2142C8.72003 16.5541 9.41959 16.758 10.1637 16.7822C8.94591 15.9671 8.14547 14.5804 8.14547 13.0058C8.14547 12.1747 8.36915 11.3947 8.7595 10.7251C10.9971 13.4693 14.3399 15.2756 18.1104 15.4642C18.0329 15.133 17.9927 14.7858 17.9927 14.4306C17.9927 11.9262 20.0234 9.89474 22.5292 9.89474C23.8341 9.89474 25.0124 10.4459 25.8399 11.3268C26.8735 11.1243 27.8443 10.7456 28.7208 10.2259C28.3808 11.2858 27.6623 12.1747 26.7251 12.7354C27.644 12.6257 28.5183 12.3816 29.3311 12.0212C28.7244 12.9327 27.9547 13.7317 27.068 14.3713Z"
                fill="black"
            />
        </svg>
    );
};
