export const IconInsta = () => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 0 18 0C8.05888 0 0 8.05888 0 18C0 27.9411 8.05888 36 18 36Z"
                fill="black"
            />
            <path
                d="M23.5871 11.1914C22.9033 11.1914 22.3486 11.7462 22.3486 12.4299C22.3486 13.1137 22.9033 13.6685 23.5871 13.6685C24.2709 13.6685 24.8257 13.1137 24.8257 12.4299C24.8257 11.7462 24.2709 11.1914 23.5871 11.1914Z"
                fill="white"
            />
            <path
                d="M18.0864 12.7734C15.2197 12.7734 12.8896 15.106 12.8896 17.9701C12.8896 20.8342 15.2223 23.1668 18.0864 23.1668C20.9505 23.1668 23.283 20.8342 23.283 17.9701C23.283 15.106 20.9505 12.7734 18.0864 12.7734ZM18.0864 21.3013C16.2492 21.3013 14.7578 19.8073 14.7578 17.9727C14.7578 16.1381 16.2518 14.6441 18.0864 14.6441C19.9209 14.6441 21.4149 16.1381 21.4149 17.9727C21.4149 19.8073 19.9209 21.3013 18.0864 21.3013Z"
                fill="white"
            />
            <path
                d="M22.2112 28.5246H13.784C10.2878 28.5246 7.44434 25.6812 7.44434 22.1849V13.7577C7.44434 10.2614 10.2878 7.41797 13.784 7.41797H22.2112C25.7075 7.41797 28.551 10.2614 28.551 13.7577V22.1849C28.551 25.6812 25.7075 28.5246 22.2112 28.5246ZM13.784 9.40478C11.3844 9.40478 9.43114 11.358 9.43114 13.7577V22.1849C9.43114 24.5845 11.3844 26.5378 13.784 26.5378H22.2112C24.6109 26.5378 26.5641 24.5845 26.5641 22.1849V13.7577C26.5641 11.3555 24.6109 9.40478 22.2112 9.40478H13.784Z"
                fill="white"
            />
        </svg>
    );
};
