import { useController, useFormContext } from "react-hook-form";

export const Input = ({ name, label }: { name: string; label: string }) => {
    const { control } = useFormContext();
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
    });
    return (
        <div className="mb-2 xs:mb-5">
            <label className="text-secondary text-xs  xs:text-base">
                {label}
            </label>
            <input
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                type="text"
                className="py-4 px-4 xs:py-4 xs:px-6 text-xs  xs:text-base bg-lightGrey rounded-3xl w-full  outline-none "
            />
            {error && <div className="textErrorSmall">{error.message}</div>}
        </div>
    );
};
